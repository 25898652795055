import React, { useState, useEffect } from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  const numberOfLines = 20;
  const sideMargin = 50; // Margin from each side
  const [lines, setLines] = useState([]);

  // Effect to generate lines only once when the component mounts
  useEffect(() => {
    const newLines = [];
    for (let i = 0; i < numberOfLines; i++) {
      const lineWidth = Math.floor(Math.random() * 3) + 1; // Random width between 1px and 3px
      const highlightStart = Math.floor(Math.random() * 80) + 10; // Random start between 10% and 90%
      const highlightEnd = highlightStart + Math.floor(Math.random() * 10) + 5; // Random end between 5% and 15% after start
      const animationDirection = Math.random() > 0.5 ? 'moveUp' : 'moveDown'; // Randomly choose the animation direction

      newLines.push(
        <div 
          key={i} 
          className="line"
          style={{
            left: `calc(${(100 / numberOfLines) * i}% + ${sideMargin}px)`, // Adjust the position for margin
            '--line-width': `${lineWidth}px`,
            '--highlight-start': `${highlightStart}%`,
            '--highlight-end': `${highlightEnd}%`,
            '--animation-direction': animationDirection
          }} 
        />
      );
    }
    setLines(newLines); // Set the state with the new lines
  }, [numberOfLines]);

  return (
    <div className="coming-soon-container">
      {lines}
      <div className="content">
        <div className="logo-container">
          <img src="/logo-advamind.svg" alt="Advamind Logo" className="coming-soon-logo" />
        </div>
        <h1>Coming Soon</h1>
        <p>Contact us: <a href="mailto:contact@advamind.net">contact@advamind.net</a></p>
      </div>
    </div>
  );
};

export default ComingSoon;
